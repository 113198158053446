<template>
    <div>
        <LandHeader />
        <BreadCrumb />
        <div class="center land-box">
            <GroupSelector/>
            <div class="info-container">
                <el-row :gutter="18" v-for="(r, index) in groupDemandList" :key="index">
                    <el-col :span="12" v-for="(o, index) in r" :key="index">
                        <DemandCard :item="o"/>
                    </el-col>
                </el-row>
              <el-empty description="暂无相关数据"  v-if="demandList.length === 0"></el-empty>
<!--

                <el-row :gutter="18" v-for="(r, index) in landDataNested" :key="index">
                    <el-col :span="6" v-for="(o, index) in r" :key="index">
                        <ListingLandItemCard :supply-info="o" />
                    </el-col>
                </el-row> -->
            </div>
        </div>
        <footer2 />
    </div>
</template>

<script>
import LandHeader from '@/components/nav'
import GroupSelector from '@/components/GroupSelector'
import DemandCard from '@/components/DemandCard'
import footer2 from "components/footer.vue";
import CommonService from "@/service/CommonService";
import {useSearchStore} from '@/stores/search';
import Nav from "@/components/nav.vue";

export default {
    name: 'DemandLand',
    components: {
        LandHeader,
        footer2,
        GroupSelector,
        DemandCard
    },

    data() {
        return {
            searchStore:useSearchStore(),
            demandList: []
        }
    },
    computed: {
        //demandList to nested array,2 items per row
        groupDemandList() {
            let list = this.demandList
            let result = []
            for (let i = 0; i < list.length; i += 2) {
                result.push(list.slice(i, i + 2))
            }
            return result
        }
    },
    mounted() {
        this.init()
      Nav.data().navStore.$patch({activeIndex:1})
        this.searchStore.$subscribe((mutation, state) => {
            this.search(state);
        })
    },
    beforeDestroy(){
        this.searchStore.$reset();
    },
    methods: {
        init() {
          this.search( this.searchStore.params);
        },
        search(selector = {}) {
            var params = {
                district:selector.land_area,
                landType:selector.land_type,
                landPurpose:selector.land_purpose,
                    // transactionWay:selector.transaction_way,
                // transactionStatus:selector.transaction_status,
                landSize:selector.land_size,
                // land_dead_line
            }

            //获取土地列表
            CommonService.getDemandLandList(params).then((res) => {
                this.demandList = res.data.records
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .info-container {
    .el-row {
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
